<template>
  <div class="slices__container">
    <slot />
    <div class="slices__slice slice-1"></div>
    <div class="slices__slice slice-2"></div>
  </div>
</template>

<script>
export const colors = [
  ['#24a8f3', '#91d5c9'],
  ['#ff937e', '#bfdfe4'],
  ['#f8ef1e', '#91d5c9'],
  ['#00c3ab', '#a7bf7f']
]

export const sliceStyle = (i = 0) => ({'--slice-color-first': colors[i % 4][0], '--slice-color-second': colors[i % 4][1]})
</script>

<style scoped lang="scss">
.slices {
  &__container {
    position: relative;
    cursor: pointer;
    display: block;
    @media (min-width: 921px) {
      &:hover {
        .slice-1 {
          transform: translate(-12px, -15px);
        }
        .slice-2 {
          transform: translate(15px, 17px);
        }
      }
    }

    > :slotted(*) {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
      position: relative;
      z-index: 2;
    }
  }

  &__slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    transition: .3s transform;
    z-index: 1;
    &.slice-1 {
      background: var(--slice-color-first);
    }
    &.slice-2 {
      background: var(--slice-color-second);
    }
  }
}
</style>
